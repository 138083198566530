import React from "react"

import Layout from "../components/layout"
import Products from "../components/products"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout id="overview">
    <SEO title="Fine art prints by Eleni Debo" />
    <Products />
  </Layout>
)

export default IndexPage
