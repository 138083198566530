import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
  fragment thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 375, quality: 95) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query {
    vdb: file(relativePath: { eq: "demarrage_thumb.jpg" }) {
      ...thumbnail
    }
    howitbegan: file(relativePath: { eq: "howitbegan_thumb.jpg" }) {
      ...thumbnail
    }
    bigbang: file(relativePath: { eq: "bigbang_thumb.jpg" }) {
      ...thumbnail
    }
    everydaystone: file(relativePath: { eq: "everydaystone_thumb.jpg" }) {
      ...thumbnail
    }
    ladyday: file(relativePath: { eq: "ladyday_thumb.jpg" }) {
      ...thumbnail
    }
    erosion: file(relativePath: { eq: "erosion_thumb.jpg" }) {
      ...thumbnail
    }
    ramen: file(relativePath: { eq: "ramen_thumb.jpg" }) {
      ...thumbnail
    }
    aperitivo: file(relativePath: { eq: "aperitivo_thumb.jpg" }) {
      ...thumbnail
    }
    aperitivoramen: file(relativePath: { eq: "aperitivoramen_thumb.jpg" }) {
      ...thumbnail
    }
    platform5: file(relativePath: { eq: "platform5_thumb.jpg" }) {
      ...thumbnail
    }
    lostinthebuzz: file(relativePath: { eq: "lostinthebuzz_thumb.jpg" }) {
      ...thumbnail
    }
    storytelling: file(relativePath: { eq: "storytelling_thumb.jpg" }) {
      ...thumbnail
    }
  }
`

const Item = ({ link, fluid, title, price }) => (
  <li className="product">
    <Link to={link}>
      <Img placeholderClassName="placeholder" fluid={fluid} />
      <div>
        <h2>{title}</h2>
        <span className="price">{price}</span>
      </div>
    </Link>
  </li>
)

const Products = () => {
  const data = useStaticQuery(query)
  return (
    <ul className="unstyled">
      <Item
        title="Demarrage"
        link="/demarrage"
        fluid={data.vdb.childImageSharp.fluid}
        price="€85,00"
      />
      <Item
        title="How it began"
        link="/howitbegan"
        fluid={data.howitbegan.childImageSharp.fluid}
        price="€70,00"
      />
      <Item
        title="Big Bang"
        link="/bigbang"
        fluid={data.bigbang.childImageSharp.fluid}
        price="€70,00"
      />
      <Item
        title="Everyday Stone"
        link="/everydaystone"
        fluid={data.everydaystone.childImageSharp.fluid}
        price="€65,00"
      />
      <Item
        title="Lady Day"
        link="/ladyday"
        fluid={data.ladyday.childImageSharp.fluid}
        price="€105,00"
      />
      <Item
        title="Erosion"
        link="/erosion"
        fluid={data.erosion.childImageSharp.fluid}
        price="€85,00"
      />
      <Item
        title="Ramen"
        link="/ramen"
        fluid={data.ramen.childImageSharp.fluid}
        price="€50,00"
      />
      <Item
        title="Aperitivo"
        link="/aperitivo"
        fluid={data.aperitivo.childImageSharp.fluid}
        price="€50,00"
      />
      <Item
        title="Aperitivo + Ramen"
        link="/aperitivoramen"
        fluid={data.aperitivoramen.childImageSharp.fluid}
        price="€90,00"
      />
      <Item
        title="Platform 5"
        link="/platform5"
        fluid={data.platform5.childImageSharp.fluid}
        price="€50,00"
      />
      <Item
        title="Lost in the buzz"
        link="/lostinthebuzz"
        fluid={data.lostinthebuzz.childImageSharp.fluid}
        price="€70,00"
      />
      <Item
        title="Storytelling"
        link="/storytelling"
        fluid={data.storytelling.childImageSharp.fluid}
        price="€70,00"
      />
    </ul>
  )
}
export default Products
